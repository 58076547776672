import React, { useState, useEffect, useRef, useCallback } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { useTranslation } from "react-i18next"
import { useAppContext, usePersistedState } from "../../AppContext"
import func from "../../services/functions"
import moment from "moment"
import mainAxios from "../../services/mainAxios"
import CashRegisterThermal from "./CashRegisterThermal"
import DateRange from "../global/DateRange"
import Select from "react-select"
import selectStyle from "../../design/selectStyle"
import { Menu } from "primereact/menu"
import { useMemo } from "react"
import ExportCsvPrime from "../global/ExportCsvPrime"
import Loader from "../Layout/Loader"
import bigDecimal from "js-big-decimal"
import OpenTcrData from "./OpenTcrData"
import { Dialog } from 'primereact/dialog';

const CashRegisterNew = (props) => {
  const [expandedRows, setExpandedRows] = useState(null)
  const { t, i18n } = useTranslation("translations")
  const [currentTcrStatPrint, setCurrentTcrStatPrint] = useState(null)
  const [stats, setStats] = useState(null)
  const [statsDate, setStatsDate] = useState(null)
  const [visible, setVisible] = useState(false)
  const {
    company: [fullCompany],
    user: [user],
    selectedCompany: [selectedCompany]
  } = useAppContext()

  const [data, setData] = useState([])
  const [pageLoading, setPageLoading] = useState(true)
  const branches = user?.companiesRights?.find(cr => selectedCompany == cr?.id)?.branches ?? []

  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
    ].concat(
      fullCompany?.branches?.map((branch) => {
        return {
          ...branch,
          label: branch.businUnitCode + " | " + branch.sellerName,
          value: branch.businUnitCode,
        }
      }) ?? []
    )
  }, [branches,fullCompany])



  const [persistedData, setPersistedData] = usePersistedState(
    null,
    "sessionData"
  )

  const businessUnitSelected = businessUnitCodes.some(
    (branch) => branch?.value === persistedData?.businessUnitCode
  )
    ? persistedData?.businessUnitCode
    : null

  const [sessionData, setSessionData] = useState({
    fromDate:
      persistedData?.fromDate && persistedData?.toDate
        ? new Date(persistedData?.fromDate)
        : func.getDateFromToday(-3),
    toDate:
      persistedData?.fromDate && persistedData?.toDate
        ? new Date(persistedData?.toDate)
        : func.getDateFromToday(1),
    businessUnitCode: businessUnitSelected,
  })

  const groupedTotalsByBranch = (_allStats) => {
    let allUserTcrs =
      branches?.reduce((acc, branch) => {
        return acc.concat(branch?.tcrTypes ?? [])
      }, []) ?? []
    const allUserTcrsMapped = allUserTcrs.map(usrTcr => usrTcr.tcrCode) ?? []
    let allStats = _allStats.filter(stat => allUserTcrsMapped.includes(stat.tcrCode) || stat?.tcrCode === "TS001NC001")
    let zeroTcrs = allUserTcrs.flat()
    zeroTcrs =
      zeroTcrs?.filter(
        (tcr) =>
          allStats.every((stat) => stat.tcrCode !== tcr.tcrCode)
      ) ?? []
    let template = {
      buTcrStats: [],
    }

    Object.keys(allStats?.[0] ?? {}).forEach((key) => {
      if (typeof allStats?.[0]?.[key] === "number") {
        template[key] = 0
      } else {
        template[key] = ""
      }
    })

    var result = []
    zeroTcrs = zeroTcrs.map((tcr) => {
      return {
        ...template,
        businessUnitCode: tcr?.businUnitCode,
        tcrCode: tcr.tcrCode,
      }
    })

    allStats.push(...zeroTcrs)
    allStats.reduce((res, current) => {
      const curr = { ...current }
      const { id, tcrCode, actionFrom, company, recordDate, ...rest } = curr

      if (!res[curr.businessUnitCode]) {
        let sellerName =
          fullCompany?.branches?.find(
            (comp) => comp.businUnitCode === curr.businessUnitCode
          )?.sellerName ?? curr.businessUnitCode

        res[curr.businessUnitCode] = rest
        curr.cashEnd =   parseFloat(bigDecimal.add( (curr?.cashEnd ?? 0), (curr?.banknoteCashTot ?? 0)))

        result.push({
          ...rest,
          cashEnd: curr.cashEnd,
          sellerName,
          buTcrStats: curr.tcrCode !== "TS001NC001" ? [curr] : [],
        })
      } else {
        let index = result.findIndex(
          (st) => st.businessUnitCode === curr.businessUnitCode
        )
        curr.cashEnd = parseFloat(bigDecimal.add( (curr?.cashEnd ?? 0), (curr?.banknoteCashTot ?? 0)))

        if (curr.tcrCode !== "TS001NC001") result[index].buTcrStats.push(curr)
        Object.keys(rest).forEach((key) => {
          if (typeof curr[key] === "number") {
            const val = result?.[index]?.[key]
            if (val || val === 0) {
              result[index][key] += curr[key]
            }
            // if (key === "cashEnd") {
            //   result[index]["cashEnd"] += (curr?.banknoteCashTot ?? 0)
            // }
          }
        })
      }
      return res
    }, {})
    return result
  }

  const getCashDesposits=()=>{
    if (!(sessionData?.fromDate && sessionData?.toDate && fullCompany?.id)) {
      return
    }
    let endpoint = sessionData?.businessUnitCode
      ? "/endpoint/v2/reports/queue/branch/aggregate"
      : "/endpoint/v2/reports/queue/company/aggregate"
    mainAxios
      .post(endpoint, {
        object: "GetQueueCashDepositStatisticsAnalytics",
        fromDate: moment(sessionData?.fromDate).format("YYYY-MM-DD"),
        toDate: moment(sessionData?.toDate).format("YYYY-MM-DD"),
        ...(sessionData?.businessUnitCode
          ? { params: { businessUnitCode: sessionData?.businessUnitCode } }
          : {}),
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.content)
          const results = groupedTotalsByBranch([...res.data.content])
          setStats(results)
          let date = moment().format("DD/MM/YYYY HH:mm")
          setStatsDate(date)
          setPageLoading(false)
        }
      })
      .catch((err) => {
        setPageLoading(false)
      })
  }
  useEffect(() => {
    getCashDesposits()
  }, [
    sessionData?.fromDate,
    sessionData?.toDate,
    sessionData?.businessUnitCode,
    fullCompany?.id,
  ])

  const printThermalInvoice = () => {
    const content = document.getElementById("thermalPrinterInvoice")
    const pri = document.getElementById("ifmcontentstoprint").contentWindow
    pri.document.open()
    pri.document.write(
      `<html><style>@page{margin:5mm auto;margin-bottom:5mm;font-family: 'Trebuchet MS', Helvetica, sans-serif;}</style>${content.innerHTML}</html>`
    )
    pri.document.close()
    pri.focus()
    pri.print()
  }
  const [tcrDataOnModal,setTcrDataOnModal]=useState()

  const openTcrData = (data, actionType, businUnitCode) => {
    const {
      cashDeposit: depositTotal,
      cashInit: initialTotal,
      tcrCode: tcr,
      cashWithdraw: withdrawTotal,
    } = data
    let tcrData = {
      depositTotal,
      initialTotal,
      tcr,
      withdrawTotal,
      cashEnd: (data?.cashEnd ?? 0)
    }
    setTcrDataOnModal({ ...tcrData, actionType, businUnitCode })

    // props.history.push({
    //   pathname: "/cashregister/tcr",
    //   state: { ...tcrData, actionType, businUnitCode },
    // })
  }

  const PrintActions = ({ row, type }) => {
    const menu = useRef()
    const items = [
      {
        label: "Simple Report",
        command: () => {
          let simpleTcrStat = {}

          Object.keys(row).forEach((key) => {
            if (row[key] !== 0) {
              simpleTcrStat[key] = row[key]
            }
          })
          setCurrentTcrStatPrint({ tcrStat: simpleTcrStat, isSimple: true })
          setTimeout(() => {
            printThermalInvoice()
          }, [150])
        },
      },
      {
        label: "Standart Report",
        command: () => {
          if ((type === "tcr" && row?.tcrCode) || type === "branch") {
            setCurrentTcrStatPrint({
              tcrStat: { ...row },
              isSimple: false,
            })
            setTimeout(() => {
              printThermalInvoice()
            }, [150])
          } else {
            setCurrentTcrStatPrint(null)
          }
        },
      },
    ]
    return (
      <div className="d-flex align-items-center">
        <Button
          tooltip={type === "branch" ? "Branch Report" : "Tcr Report"}
          tooltipOptions={{
            position: "left",
            style: { padding: 0 },
          }}
          // label={type === "branch" ? "Branch Report" : "Tcr Report"}
          icon="pi pi-print"
          disabled={data?.length == 0}
          className="p-button-outlined p-button-success"
          style={{
            padding: "5px 8px",
            fontWeight: 600,
            fontSize: 12,
          }}
          onClick={(event) => menu.current.toggle(event)}
        />
        <Menu model={items} popup ref={menu} />
        {type == "tcr" && (
          <Button
            tooltip={t("navbar.cashRegister")}
            tooltipOptions={{
              position: "left",
              style: { padding: 0 },
            }}
            icon="pi pi-reply"
            className="p-button-outlined p-button-success ml-2"
            style={{
              padding: "5px 8px",
              fontWeight: 600,
              fontSize: 12,
            }}
            onClick={(e) =>{
              setVisible(true);
              openTcrData(
                {
                  ...row,
                  initialTotal:
                    row?.cashEnd ? row?.cashEnd : row?.initialTotal,
                },
                "INITIAL",
                row?.businessUnitCode
              )}
            }
          />
        )}
      </div>
    )
  }
  const allowExpansion = (rowData) => {
    return rowData?.buTcrStats?.length > 0
  }

  const branchColumns = [
    {
      expander: allowExpansion,
      style: { width: "0.5em" },
      field: "expansionCol",
      frozen: true,
    },
    {
      field: "sellerName",
      header: t("cashRegister.branchName"),
      sortable: true,
    },
    {
      field: "banknoteCashTot",
      header: t("dashboard.salesCash"),
      body: (row) => func.getFormattedMoneyValue(row?.banknoteCashTot ?? 0),
    },
    {
      field: "cashDeposit",
      header: t("invoice.corrective"),
      body: (row) => func.getFormattedMoneyValue(row?.cashCorrectiveNo ?? 0),
      // style: { textAlign: "right" }
    },
    {
      field: "totals",
      header: t("dashboard.totals"),
      body: (row) =>
        func.getFormattedMoneyValue(
          (row?.cashEnd ?? 0)
        ),
    },
    {
      field: "actions",
      header: "Actions",
      body: (row) => PrintActions({ row, type: "branch" }),
    },
  ]

  const defaultCols = [
    "tcrCode",
    "cashInit",
    "cashInitNo",
    "cashWithdrawNo",
    "cashWithdraw",
    "cashDeposit",
    "cashDepositNo",
    "totals",
    "actions",
  ]
  const [selectedColumns, setSelectedColumns] = usePersistedState(
    defaultCols,
    "tcrColumns"
  )
  const onColumnToggle = (event) => {
    let selected = event?.value
    const cols = selected.filter(sel => !sel.persisted && !sel.frozen).map((sel) => sel.field)
    setSelectedColumns(cols)
  }

  // const {...defaultCols,...restColumns}=data?.[0] ?? {}

  const columns = useMemo(() => {
    const restCols =
      Object.keys(data?.[0] ?? {})?.filter(
        (key) => !defaultCols.includes(key) && key != "cashEnd"
      ) ?? []

    return [
      {
        field: "actions",
        header: t("cashRegister.actions"),
        style: { minWidth: "120px", maxWidth: "120px" },
        body: (row) => PrintActions({ row, type: "tcr" }),
        frozen: true,
        persisted: true
      },
      {
        field: "tcrCode",
        filter: true,
        showFilterMenu: false,
        showClearButton: false,
        header: t("cashRegister.tcrName"),
        style: { minWidth: "150px", maxWidth: "150px" },
        sortable: true,
        persisted: true
      },
      {
        field: "cashInit",
        header: t("cashRegister.initialTitle"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.cashInit ?? 0),
        persisted: true
        // style: { textAlign: "right" }
      },
      {
        field: "cashInitNo",
        header: t("cashRegister.initialNo"),
        style: { minWidth: "150px", maxWidth: "150px" },
        persisted: true
      },
      {
        field: "cashWithdraw",
        header: t("cashRegister.withdrawTotal"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.cashWithdraw ?? 0),
        persisted: true
        // style: { textAlign: "right" }

      },
      {
        field: "cashWithdrawNo",
        header: t("cashRegister.withdrawNo"),
        style: { minWidth: "150px", maxWidth: "150px" },
        persisted: true
      },
      {
        field: "cashDeposit",
        header: t("cashRegister.cashDepTot"),
        style: { minWidth: "150px", maxWidth: "150px" },
        // body: (row) => func.getFormattedMoneyValue(row?.cashDeposit ?? 0),
        persisted: true
      },
      {
        field: "cashDepositNo",
        style: { minWidth: "150px", maxWidth: "150px" },

        header: t("cashRegister.cashDepNo"),
        persisted: true
      },
      {
        field: "cashEnd",
        style: { minWidth: "150px", maxWidth: "150px" },

        header: t("dashboard.totals"),
        // body: (row) => {
        //   return func.getFormattedMoneyValue((row?.cashEnd ?? 0))
        // },
        persisted: true
      },


      ...restCols.map((key) => {
        let header = func.camelToFlat(key);
        let field = key;
        if (i18n.exists(`translations:stat.${key}`)) {
          header = t(`stat.${key}`);
        }
        return {
          header,
          field,
          style: { minWidth: "150px", maxWidth: "250px" },

        };
      }),
    ]
  }, [i18n.language, stats, data])

  const filteredColumns = useMemo(() => {
    let _filteredColumns = []
    selectedColumns.forEach((persistedCol) => {
      const column = columns.find((col) => col.field === persistedCol && !col?.frozen && !col?.persisted)
      if (column) _filteredColumns.push(column)
    })
    let persisted = columns?.filter((col) => col?.frozen || col?.persisted) ?? []

    return persisted.concat(_filteredColumns)
  }, [selectedColumns, data, i18n.language])

  const header = useMemo(() => {
    const filteredOptions = columns.filter(col => col.persisted || col.frozen)?.map(col => ({ field: col.field, persisted: true })) ?? []
    let value = columns.map(opt => opt.field) ?? []
    value = selectedColumns.filter(selectedField => value.includes(selectedField)).map(col => ({ field: col }))

    return (
      <div
        className="d-flex justify-content-end p-0"
        style={{ textAlign: "left" }}
      >
        <MultiSelect
          value={filteredOptions.concat(value)}
          scrollHeight="600px"
          dataKey="field"
          filter={true}
          options={columns}
          optionLabel="header"
          onChange={onColumnToggle}
          style={{ width: "14em" }}
          itemTemplate={(option) => {
            const disabled = option?.persisted;
            const style = { color: "#c1c1c1", fontweight: 500 }
            return <div style={disabled ? style : {}}>{option.header}</div>
          }}
        />
      </div>
    )
  }, [selectedColumns, data])

  const rowExpansionTemplate = (data) => {
    return (
      <div className="prime-header-sticky" style={{ width: "100vw" }}>
        <DataTable
          reorderableColumns={true}
          // rowClassName="table-sub-header"
          filterDisplay="row"
          value={data.buTcrStats}
          responsiveLayout="scroll"
          className="white-header"
          dataKey="tcrCode"
          columnResizeMode="expand"
          scrollable={true}
          scrollHeight="400px"

        >
          <Column key="space" frozen={true} field="space" style={{ minWidth: "100px",maxWidth:"100px" }} />
          {filteredColumns?.map((column) => {
            return <Column key={column?.field} {...column} />
          })}
          <Column key="spaceAfter" frozen={true} field="spaceAfter" style={{ width: "20px" }} />

        </DataTable>
      </div>
    )
  }

  const exportColumns = useCallback(
    ({ simpleExport }) => {
      if (simpleExport) {
        return (
          filteredColumns?.map(({ field }) => ({
            field,
            header: func.camelToFlat(field),
          })) ?? []
        )
      }

      const newColumns =
        Object.keys(data[0] ?? {})?.map((key) => {
          return {
            header: func.camelToFlat(key),
            field: key,
          }
        }) ?? []
      return newColumns
    },
    [data, i18n.language]
  )
  if (pageLoading) return <Loader />

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-1">
      <Dialog header={t("cashRegister.action")}  visible={visible} style={{ width: '45vw', zIndex:-100 }} onHide={() => setVisible(false)}>
              {visible&& <OpenTcrData data={tcrDataOnModal}  onChange={()=>{getCashDesposits()}} onClose={()=>{setVisible(false)}} />}
      </Dialog>
        <h3 className="mt-1">{t("navbar.cashRegister")}</h3>
        <small className="ml-3">
          <ExportCsvPrime
            data={data}
            excludedColumns={["space", "actions"]}
            columns={exportColumns({ simpleExport: true })}
          />
        </small>
        <small className="ml-3">
          <ExportCsvPrime
            label="Export All"
            data={data}
            columns={exportColumns({ simpleExport: false })}
            excludedColumns={["space"]}
          />
        </small>

        <div className="d-none">
          {currentTcrStatPrint?.tcrStat && (
            <CashRegisterThermal
              tcrStat={{
                fromDate: sessionData.fromDate,
                toDate: sessionData.toDate,
                statsDate: statsDate,
                ...currentTcrStatPrint?.tcrStat,
              }}
              isSimple={currentTcrStatPrint.isSimple}
            />
          )}
          <iframe id="ifmcontentstoprint"></iframe>
        </div>
        <div className="ml-md-auto mb-4 mb-md-0">
          <DateRange
            fromDate={sessionData.fromDate}
            toDate={sessionData.toDate}
            onChange={(dates) => {
              let fromDate = dates[0]
              let toDate = dates[1]
              if (fromDate && toDate) {
              }
              setPersistedData({ ...persistedData, fromDate, toDate })
              setSessionData({ ...sessionData, fromDate, toDate })
            }}
          />
        </div>
        <div className="col-6 col-md-2 d-flex align-items-center">
          <label className="align-items-center mr-2 mb-0">BU</label>
          <Select
            styles={selectStyle}
            className="w-100"
            options={businessUnitCodes}
            value={
              businessUnitCodes.find(
                (businessUnitCode) =>
                  businessUnitCode.value === sessionData.businessUnitCode
              ) || {
                label: t("invoice.businessUnitCode"),
                value: "",
              }
            }
            isSearchable={true}
            onChange={(businessUnitCode) => {
              setPersistedData({
                ...persistedData,
                businessUnitCode: businessUnitCode.value,
              })
              setSessionData({
                ...sessionData,
                businessUnitCode: businessUnitCode.value,
              })
            }}
            name="businessUnitCode"
          />
        </div>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body p-0">
          <div className="datatable-rowgroup-demo cash-register">
            <DataTable
              rowClassName="datadatble-row-style"
              value={stats}
              expandedRows={expandedRows}
              stripedRows
              header={header}
              onRowToggle={(e) => setExpandedRows(e.data)}
              responsiveLayout="stack"
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="businessUnitCode"
              filterDisplay="menu"
            >
              {branchColumns.map((column) => {
                return <Column {...column} />
              })}

            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default CashRegisterNew
